import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const EventoPage = () => (
  <Layout>
    <SEO title="Evento" />
    <section className="intro">
      <div className="intro-container">
        <h2>Evento</h2>
        <p>Fundado em 2019, o Mr. Rubber Brasil é o primeiro evento rubber na América do Sul e o segundo concurso rubber na América Latina. A segunda edição do evento acontecerá de 23 a 25 de setembro de 2022, e a segunda edição do concurso acontecerá no dia 24 de setembro de 2022 na Upgrade Club em São Paulo.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <p>Mais informações chegando em breve.</p>
        <p><Link to="/" className="call-to-action">Voltar à página inicial</Link></p>
      </div>
    </section>
  </Layout>
)

export default EventoPage
